/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { Spin } from 'antd';
import {
  AmplifyAuthenticator, AmplifySignIn,
} from '@aws-amplify/ui-react';
import Amplify, {
  Auth, Hub,
} from 'aws-amplify';
import { useLocation } from '@reach/router';
import dayjs from 'dayjs';
import awsconfig, { CustomConfig } from '../backendConfigProvider';
import * as styles from '../styles/Login.module.scss';
import { logout } from '../components/Shared/Services/AuthService';
import { getPendingInvitations } from '../services/InvitationService';
import { fetchUserBySub } from '../components/DataStore/UserService';
import { isBDMUser, postEvent } from '../services/utils';
import changeSync from '../services/DataStoreConfigure';

Amplify.configure(awsconfig);

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showBrowserNotSupportedScreen, setShowBrowserNotSupportedScreen] = useState(false);

  const location = useLocation();

  const triggerEvents = async (userProfile) => {
    try {
      const userId = userProfile?.id;
      const enterpriseId = userProfile?.enterpriseID;
      const phone = userProfile?.phoneNumber;
      const title = userProfile?.designation;
      const payload = {
        userId,
        enterpriseId,
        originalTimestamp: dayjs().toISOString(),
        sentAt: dayjs().toISOString(),
        traits: {
          email: userProfile?.email,
          firstName: userProfile?.firstName,
          lastName: userProfile?.lastName,
          name: userProfile?.name,
          roles: userProfile?.roles,
          ...(phone && { phone }),
          ...(title && { title }),
          createdAt: userProfile?.createdAt,
          lastActive: dayjs().toISOString(),
          exclude: isBDMUser(userProfile?.email),
        },
      };
      postEvent(payload, '/identify');
      window.dataLayer.push({
        event: 'identify',
        userId: `${CustomConfig.backendEnv}:${userId}`,
      }, 20);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * If the user is not present in DS then create one
   * for first time login
   *
   * @param {*} loggedUser
   */
  const preLoginActions = async () => {
    // refresh token and proceed to ensure permissions are reflected
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const pendingInvitations = await getPendingInvitations(cognitoUser);
    const sub = cognitoUser?.attributes?.sub;
    const id = cognitoUser?.attributes?.['custom:active_user'];
    const user = await fetchUserBySub(sub, id);
    if (user) {
      triggerEvents(user);
    }
    const currentSession = await Auth.currentSession();
    cognitoUser.refreshSession(currentSession.refreshToken, () => {
      setIsLoading(false);
      if (location?.state?.throughSignupFlow) {
        navigate('/workspaces/', { replace: true, state: { throughSignupFlow: location.state.throughSignupFlow } });
        return;
      }
      if (location?.state?.inviteeSignupFlow) {
        navigate('/workspaces/', { replace: true, state: { inviteeSignupFlow: location.state.inviteeSignupFlow } });
        return;
      }
      if (pendingInvitations?.length || !user) {
        navigate('/workspaces/', { replace: true });
        return;
      }

      navigate('/', { replace: true });
    });
  };

  const onAuthEvent = async (payload) => {
    if (payload?.event === 'signIn') {
      const loggedUser = payload?.data;
      console.log(loggedUser);
      setIsLoading(true);
      // refresh datastore
      try {
        const sub = loggedUser?.attributes?.sub;
        await changeSync(sub, true);
      } catch (e) {
        if (e) {
          logout(false);
          setShowBrowserNotSupportedScreen(true);
        }
      }
    }
  };

  // listen to signIn event
  useEffect(() => {
    const authListener = Hub.listen('auth', (data) => {
      const { payload } = data;
      onAuthEvent(payload);
    });
    // Create listener
    const datastoreListener = Hub.listen('datastore', async (hubData) => {
      const { event, data } = hubData.payload;
      if (event === 'ready') {
        console.log('DS listnerr', data);

        // do something here once the data is synced from the cloud
        preLoginActions();
      }
    });

    return () => {
      authListener();
      datastoreListener();
    };
  }, []);

  useEffect(async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      if (cognitoUser) {
        // Navigates to homepage
        const sub = cognitoUser?.attributes?.sub;
        changeSync(sub, true);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    !showBrowserNotSupportedScreen ? (
      <>
        {isLoading ? <Spin tip="Signing In..." style={{ height: '100vh', width: '100vw' }} />
          : (
            <AmplifyAuthenticator>
              <AmplifySignIn hideSignUp slot="sign-in" />
            </AmplifyAuthenticator>
          )}
      </>
    ) : (
      <div className={styles.browserNotSupported}>
        <p>
          Something Went Wrong, please try again on a different browser
        </p>
      </div>
    )
  );
};

export default Login;
